import { render, staticRenderFns } from "./CaWidgetButtons.vue?vue&type=template&id=0a04f4b6"
import script from "./CaWidgetButtons.vue?vue&type=script&lang=js"
export * from "./CaWidgetButtons.vue?vue&type=script&lang=js"
import style0 from "./CaWidgetButtons.vue?vue&type=style&index=0&id=0a04f4b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaButton/CaButton.vue').default})
